<template>
  <div class="groupSelector">
    {{ entitySelected }}
  <div class="overflow-scroll" style="width: 300px; height: 300px;">
    <div v-if="!loading" class="">
      <el-checkbox-group class="d-flex align-items-start w-100 flex-column" v-model="entitySelected" >
        <el-checkbox
          :label="visitor.id"
          v-model="visitadorasActivas"
          v-for="visitor in groupVisitorList"
          v-bind:key="visitor.id"
        >
          {{ visitor.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-else class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
    {{ entitySelected.length }} visitadoras seleccionadas.
    <div class="mt-5">
      <div class= "btn-group" role="group" mt-5 aria-label="Basic example">
        <button type="button" class="btn btn-primary" @click="agregarVisitadora">Agregar</button>
        <button type="button" class="btn btn-primary" @click="moverVisitadora" :disabled="entitySelected.length<1">Mover</button>
      </div>
    </div>
  </div>

</template>
<style lang="scss" scoped>
 .el-checkbox {
   height: 1.8em;
 }
 .groupSelector {
   border: 1px solid #282828 !important;
   display: block;
   border-radius: 10px;
   padding: 10px;
 }


</style>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
export default {
  name: "GroupSelector",
 props:{
    entityList:Array

 },emits: ["changed"],
  computed: {
    totalCount() {
      return this.entitySelected.length;
    },
  },
  mounted() {
    this.getVisitadora();
    this.getGrupos();
  },
  data() {
    return {
      loading: false,
      errors: {},
      visitorList: [],
      groupVisitorList: this.entityList,
      selectedVisitor: '',
      groupList: [],
      entitySelected: [],
      showAdd: false,
      visitadorasActivas: [],
    };
  },
  methods: {

    catchErrors(error) {
      Swal.fire({
        text: "Ocurrió un error inesperado. Inténtelo nuevamente o consulte al administrador de sistema.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.errors = error.response.data.errors;
    },
    agregarVisitadora(){
      Swal.fire({
        title: 'Seleccione visitadora',
        //text: "Esta operación es irreversible!",
        icon: 'question',
        input: 'select',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        inputOptions: this.visitorList,
        confirmButtonText: 'Mover',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.groupVisitorList.push({ name: this.visitorList[result.value], id: result.value })
        }
      });
    },
    moverVisitadora(){
      Swal.fire({
        title: 'Seleccione Nuevo Grupo',
        //text: "Esta operación es irreversible!",
        icon: 'question',
        input: 'select',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
        inputOptions: this.groupList,
        confirmButtonText: 'Mover',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
            if (result.isConfirmed) {
                ApiService.post()
            }
          })
    },
    eliminarVisitadora(){
      Swal.fire({
        title: 'Está seguro?',
        text: "Esta operación es irreversible!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, Borrar Visitadora!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
              'Eliminada!',
              'La visitadora ha sido correctamente eliminada del grupo.',
              'success'
          )
        }
      })
    },
    getVisitadora() {
      ApiService.query("index/users", {
        params: {
          permission: "place_visit",
                  },
      }).then((response) => {
        this.visitorList = response.data;
      });

    },
    getGrupos() {
      ApiService.query("index/groups", {

      }).then((response) => {
        this.groupList = response.data;
      });

    },
  },
};
</script>
